import React, { useEffect, useState } from 'react';
import { cryptoListPairSymbols } from '../services/CryptoPairSymbols';

const TransactionTableBinance = ({ transactions, addTransaction }) => {
  const generateRandomTransaction = () => {
    const pairs = cryptoListPairSymbols;
    const sides = ['Buy', 'Sell'];
    const types = ['Limit Order', 'Market Order'];
    const getRandom = (arr) => arr[Math.floor(Math.random() * arr.length)];
    const onChains = ['On-chain', 'Off-chain'];
    let pairSymbol = getRandom(pairs)
    return {
      time: new Date().toLocaleString(),
      pair: pairSymbol,
      side: getRandom(sides),
      type: getRandom(types),
      filled: (Math.random() * 100).toFixed(5) + ' USDT',
      // amount: (Math.random() * 100).toFixed(2) + ' ' + getRandom(pairs).split('/')[0],
      amount: (Math.random() * 100).toFixed(2) + ' ' + pairSymbol,
      // amount: (Math.random() * 10000).toFixed(2),
      volume: (Math.random() * 10).toFixed(2) + ' USDT',
      fee: (Math.random() * 10).toFixed(2) + ' USDT',
      onChain: getRandom(onChains),
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const transaction = generateRandomTransaction();
      addTransaction(transaction); // Pass the generated transaction to the parent
    }, 3000);

    return () => clearInterval(interval);
  }, [addTransaction]);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto bg-gray-800 text-white transac-table">
        <thead className="bg-gray-900">
          <tr>
            <th className="px-4 py-2 text-left">Time</th>
            <th className="px-4 py-2 text-left">Pair</th>
            <th className="px-4 py-2 text-left">Side</th>
            {/* <th className="px-4 py-2 text-left">Type</th> */}
            <th className="px-4 py-2 text-left">Filled Price</th>
            <th className="px-4 py-2 text-left">Amount</th>
            <th className="px-4 py-2 text-left">Volume</th>
            <th className="px-4 py-2 text-left">On-chain</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((tx, index) => (
            <tr key={index} className="border-b border-gray-700">
              <td className="px-4 py-2">{tx.time}</td>
              <td className="px-4 py-2">{tx.pair}</td>
              <td className="px-4 py-2">{tx.side}</td>
              {/* <td className="px-4 py-2">{tx.type}</td> */}
              <td className="px-4 py-2 shaded-cell">{tx.filled}</td>
              <td className="px-4 py-2">{tx.amount}</td>
              <td className="px-4 py-2">${Number(tx.volume.replace(' USDT', '')).toLocaleString()}</td>
              <td className="px-4 py-2 shaded-cell">{tx.onChain}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTableBinance;
